import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import {aboutUsUrl, careersUrl, downloadUrl, homeUrl, missionUrl, pressUrl} from '../../const'
import grazerLogo from '../../resources/grazerLogo.svg'
import menuIcon from '../../resources/menuIcon.svg'
import closeIcon from '../../resources/closeIcon.svg'
import instaLogo from '../../resources/instaLogo.svg'
import twitterLogo from '../../resources/twitterLogo.svg'
import tiktokLogo from '../../resources/tiktokLogo.svg'
import './index.css'

const MENU_LINKS = [
    { url: missionUrl, text: 'MISSION' },
    { url: pressUrl, text: 'PRESS' },
    { url: careersUrl, text: 'CAREERS' },
    { url: aboutUsUrl, text: 'US' }
]

export const PageHeader = () => {
    const { pathname } = useLocation()
    const [firstSideMenuRender, setFirstSideMenuRender] = React.useState(true)
    const [sideMenuOpen, setSideMenu] = React.useState(false)

    React.useEffect(() => {
        setTimeout(() => setSideMenu(false), 300)
    }, [pathname])

    return (
        <>
            <div className="page-header__container">
                <Link to={homeUrl}>
                    <img
                        src={grazerLogo}
                        alt="grazer-logo"
                        className="page-header__grazer-logo"
                    />
                </Link>

                <img
                    src={sideMenuOpen ? closeIcon : menuIcon}
                    alt={sideMenuOpen ? 'close-menu' : 'open-menu'}
                    className="page-header__menu-icon"
                    onClick={() => {
                        if (sideMenuOpen) { setFirstSideMenuRender(false) }
                        setSideMenu((!sideMenuOpen))
                    }}
                />

                <div className="page-header__buttons-container">
                    {MENU_LINKS.map(({ url, text }) => (
                        <Link
                            key={url}
                            to={url}
                            className={`page-header__link ${pathname === url ? 'pressed' : ''}`}
                        >
                            {text}
                        </Link>
                    ))}

                    <Link
                        to={downloadUrl}
                        className="desktop page-header__download-button"
                    >
                        DOWNLOAD
                    </Link>
                </div>
            </div>

            <div
                className={`
                page-header__side-menu-container 
                ${sideMenuOpen ? 'open' : ''} 
                ${!firstSideMenuRender && !sideMenuOpen && 'closed'
                }`}
            >
                <div className="page-header__side-menu__content">
                    {MENU_LINKS.map(({ url, text }) => (
                        <Link
                            key={url}
                            to={url}
                            className={`page-header__side-menu__link ${pathname === url ? 'pressed' : ''}`}
                        >
                            {text}
                        </Link>
                    ))}
                </div>

                <div className="page-header__side-menu__footer">
                    <Link
                        to={downloadUrl}
                        className="page-header__download-button"
                    >
                        DOWNLOAD
                    </Link>

                    <div className="page-header__side-menu__icons-container">
                        <a href="https://www.instagram.com/grazerapp" target="_blank" rel="noopener">
                            <img
                                alt="instagram-link"
                                src={instaLogo}
                            />
                        </a>

                        <a href="https://twitter.com/grazer_app" target="_blank" rel="noopener">
                            <img
                                alt="twitter-link"
                                src={twitterLogo}
                            />
                        </a>

                        <a href="https://www.tiktok.com/@grazerapp?lang=en" target="_blank" rel="noopener">
                            <img
                                alt="tiktok-link"
                                src={tiktokLogo}
                            />
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}
