import React from 'react'
import grazerLogoDark from '../../resources/grazerLogoDark.svg'
import '../common.scss'

export const Terms = () => (
    <div className="common-page-inner-content">
        <div className="common-page-title">Terms of service</div>

        <div className="common-page-paragraph">
            Hey herbivores! Welcome to Grazer's Terms and Conditions of Use
            (these "Terms"). Our lawyers insist that we impose rules on users to
            protect all of our hard work. This is a contract between you and the
            Grazer Holdings Limited (as defined further below) and we want you to
            know yours and our rights before you use the Grazer application ("App").
            Please take a few moments to read these Terms before enjoying the App,
            because once you access, view or use the App, you are going to be legally
            bound by these Terms (so probably best to read them first!).
        </div>

        <div className="common-page-subtitle">
            1. GRAZER RULES
        </div>

        <div className="common-page-paragraph">
            Before you can use our awesome App, you will need to register for an account
            ("Account"). In order to create an Account you must: {'\n'}
            1. be at least 18 years old; and {'\n'}
            2. be legally permitted to use the App by the laws of your home country. {'\n'}
            You can create an Account via manual registration, or by using your Facebook login details.
            If you create an Account using your Facebook login details, you authorize us to access,
            display and use certain information from your Facebook account (e.g. profile pictures,
            relationship status, location and information about Facebook friends).
            For more information about what information we use and how we use it, please check out our
            Privacy Policy.
        </div>

        <div className="common-page-paragraph">
            Unfortunately, we cannot allow you to use another person's Account
            without permission - that just wouldn't be fair!
        </div>

        <div className="common-page-paragraph">
            You'll have great fun on Grazer, but if you feel the need to leave, you can delete your
            Account at any time by going to the 'Settings' page when you are logged in and clicking
            on the 'Delete account' link. Your Account will be deleted immediately but it may take a
            little while for Your Content (defined below) to be completely removed from the App.
            We reserve the right at our sole discretion to terminate or suspend any Account,
            or make use of any operational, technological, legal or other means available to enforce
            the Terms (including without limitation blocking specific IP addresses), at any time
            without liability and without the need to give you prior notice.
        </div>

        <div className="common-page-paragraph">
            You may not access, tamper with, or use non-public areas of the App or our systems.
            Certain portions of the App may not be accessible if you have not registered for an
            Account.
        </div>

        <div className="common-page-subtitle">
            2. TYPES OF CONTENT
        </div>

        <div className="common-page-paragraph">
            There are three types of content that you will be able to access on the App: {'\n'}
            1. content that you upload and provide ("Your Content"); {'\n'}
            2. content that members provide ("Member Content"); and {'\n'}
            3. content that Grazer Holdings Limited provides ("Our Content").
        </div>

        <div className="common-page-paragraph">
            There is certain content we can't allow on Grazer
        </div>

        <div className="common-page-paragraph">
            We want our users to be able to express themselves as much as possible and post all sorts of
            things on Grazer, but we have to impose restrictions on certain content which: {'\n'}
            • contains language or imagery which could be deemed offensive or is likely to harass, upset,
            embarrass, alarm or annoy any other person; {'\n'}
            • is obscene, pornographic, violent or otherwise may offend human dignity; {'\n'}
            • is abusive, insulting or threatening, discriminatory or which promotes or encourages
            racism, sexism, hatred or bigotry; {'\n'}
            • encourages any illegal activity including, without limitation, terrorism, inciting racial
            hatred or the submission of which in itself constitutes committing a criminal offence; {'\n'}
            • is defamatory or libellous; {'\n'}
            • relates to commercial activities (including, without limitation, sales, competitions and advertising,
            links to other websites or premium line telephone numbers); {'\n'}
            • involves the transmission of "junk" mail or "spam"; {'\n'}
            • contains any spyware, adware, viruses, corrupt files, worm programmes or other malicious code
            designed to interrupt, damage or limit the functionality of or disrupt any software,
            hardware, telecommunications, networks, servers or other equipment, Trojan horse or any other material
            designed to damage, interfere with, wrongly intercept or expropriate any data or personal information
            whether from Grazer or otherwise; {'\n'}
            • itself, or the posting of which, infringes any third party's rights (including, without limitation,
            intellectual property rights and privacy rights); {'\n'}
            • shows another person which was created or distributed without that person’s consent.
        </div>

        <div className="common-page-paragraph">
            Grazer operates a zero-tolerance policy for this kind of content.
        </div>

        <div className="common-page-subsection-title">
            Your Content
        </div>

        <div className="common-page-paragraph">
            As Your Content is unique, you are responsible and liable for Your Content and will indemnify, defend,
            release, and hold us harmless from any claims made in connection with Your Content.
            Sorry that was a bit of a mouthful, but you are what you post!
        </div>

        <div className="common-page-paragraph">
            You may not display any personal contact or banking information on your individual profile page whether
            in relation to you or any other person (for example, names, home addresses or postcodes,
            telephone numbers, email addresses, URLs, credit/debit card or other banking details).
            If you do choose to reveal any personal information about yourself to other users, whether via email
            or otherwise, it is at your own risk. We encourage you to use the same caution in disclosing details
            about yourself to third parties online as you would under any other circumstances.
        </div>

        <div className="common-page-paragraph">
            As Grazer is a public community, Your Content will be visible to other users of the App all around
            the world instantly - so make sure you are comfortable sharing Your Content before you post.
            As such, you agree that Your Content may be viewed by other users and any person visiting,
            participating in or who is sent a link to the App (e.g. individuals who receive a link to a user’s
            profile or shared content from other Grazer Users). By uploading Your Content on Grazer, you represent
            and warrant to us that you have all necessary rights and licenses to do so, and automatically grant us
            a non-exclusive, royalty-free, perpetual, worldwide license to use Your Content in any way (including,
            without limitation, editing, copying, modifying, adapting, translating, reformatting, creating
            derivative works from, incorporating into other works, advertising, distributing and otherwise making
            available to the general public such Content, whether in whole or in part and in any format or medium
            currently known or developed in the future).
        </div>

        <div className="common-page-paragraph">
            We may assign and/or sub-licence the above license to our affiliates and successors without
            any further approval by you.
        </div>

        <div className="common-page-paragraph">
            We have the right to remove, edit, limit or block access to any of Your Content at any time,
            and we have no obligation to display or review Your Content.
        </div>

        <div className="common-page-subsection-title">
            Member Content
        </div>

        <div className="common-page-paragraph">
            Other members of Grazer will also share content via the App. Member Content belongs to the user who
            posted the content and is stored on our servers and displayed via the App at the direction of the user
            providing the Member Content.
        </div>

        <div className="common-page-paragraph">
            You do not have any rights in relation to other users' Member Content, and you may only use other Grazer
            users' personal information to the extent that your use of it matches Grazer's purpose of allowing
            people to meet one another. You may not use other users' information for commercial purposes, to spam,
            to harass, or to make unlawful threats. We reserve the right to terminate your Account if you misuse
            other users' information.
        </div>

        <div className="common-page-paragraph">
            Member Content is subject to the terms and conditions of Sections 512(c) and/or 512(d) of the Digital
            Millennium Copyright Act 1998. If you have a complaint about Member Content, please see the Digital
            Millennium Copyright Act section below for more information.
        </div>

        <div className="common-page-subsection-title">
            Our Content
        </div>

        <div className="common-page-paragraph">
            You may be wondering what happens to the rest of the Content on Grazer. Well, it belongs to us!
            Any other text, content, graphics, user interfaces, trademarks, logos, sounds, artwork, and other
            intellectual property appearing on Grazer are owned, controlled or licensed by us and are protected by
            copyright, trademark and other intellectual property law rights. All right, title and interest in and
            to Our Content remains with us at all times.
        </div>

        <div className="common-page-paragraph">
            We grant you a non-exclusive, limited, personal, non-transferable, revocable, license to access and
            use Our Content, without the right to sublicense, under the following conditions: {'\n'}
            • you shall not use, sell, modify, or distribute Our Content except as permitted
            by the functionality of the App; {'\n'}
            • you shall not use our name in metatags, keywords and/or hidden text; {'\n'}
            • you shall not create derivative works from Our Content or commercially exploit Our Content,
            in whole or in part, in any way; and {'\n'}
            • you shall use Our Content for lawful purposes only.
        </div>

        <div className="common-page-paragraph">
            We reserve all other rights.
        </div>

        <div className="common-page-subtitle">
            3. RESTRICTIONS ON THE APP
        </div>

        <div className="common-page-subsection-title">
            You agree to:
        </div>
        <div className="common-page-paragraph">
            • comply with all applicable laws, including without limitation, privacy laws, intellectual property
            laws, anti-spam laws, equal opportunity laws and regulatory requirements; {'\n'}
            • use your real name on your profile; {'\n'}
            • use the services in a professional manner.
        </div>

        <div className="common-page-paragraph">
            • act in an unlawful or unprofessional manner including being dishonest, abusive or discriminatory; {'\n'}
            • misrepresent your identity, your current or previous positions, qualifications or affiliations with
            a person or entity; {'\n'}
            • disclose information that you do not have the consent to disclose; {'\n'}
            • create or operate a pyramid scheme, fraud or other similar practice.
        </div>

        <div className="common-page-paragraph">
            We don't like users misbehaving in the Grazer community – users should not do bad things to other users.
            Therefore, you can report any abuse or complain about Member Content by contacting us, outlining
            the abuse and/or complaint. You can also report a user directly from a profile or in chat by clicking
            the 'Report' option and unmatch with a user by swiping left on the message page and click 'Unmatch'.
        </div>

        <div className="common-page-paragraph">
            ​Also, we don't appreciate users doing bad things to Grazer - we've worked hard on our creation, so
            scraping or replicating any part of the App without our prior consent is expressly prohibited. This
            includes by any means (automated or otherwise) other than through our currently available, published
            interfaces - unless you have been specifically allowed to do so in a separate agreement with us.
        </div>

        <div className="common-page-subtitle">
            4. PRIVACY
        </div>

        <div className="common-page-paragraph">
            For information about how Grazer Holdings Limited collects, uses, and shares your personal data, please
            check out our Privacy Policy – this is important stuff, and makes for great bedtime reading! By using
            Grazer, you agree that we can use such data in accordance with our Privacy Policy.
        </div>

        <div className="common-page-subtitle">
            5. THIRD-PARTY STORES
        </div>

        <div className="common-page-paragraph">
            The App may be dependent on and/or interoperate with third-party owned and/or operated platforms and
            services, e.g., Apple (iTunes, etc.), Google, Facebook, Twitter, etc. (each, a “Third Party Platform”)
            and may require that you be a registered member of such Third Party Platforms and provide certain
            account credentials and other information in order to access the App. By using the App, you agree to
            comply with any applicable terms, conditions or requirements promulgated by any provider of a Third
            Party Platform (e.g., Facebook’s Terms of Use, iTunes Store Terms of Use, etc.).
        </div>

        <div className="common-page-paragraph">
            In the event of a conflict between a Third Party Store’s terms and conditions and these Terms, the
            terms and conditions of the Third Party Store or service provider shall govern and control. We are
            not responsible and have no liability whatsoever for goods or services you obtain through the Third
            Party Store, our third-party service providers or other web sites or web pages. We encourage you to
            make whatever investigation you feel necessary or appropriate before proceeding with any online
            transaction with any of these third parties.
        </div>

        <div className="common-page-subtitle">
            6. PUSH NOTIFICATIONS; LOCATION-BASED FEATURES
        </div>

        <div className="common-page-paragraph">
            We may provide you with emails, text messages, push notifications, alerts and other messages related
            to the App and/or the Grazer services, such as enhancements, offers, products, events, and other
            promotions. After downloading the App, you will be asked to accept or deny push notifications/alerts.
            If you deny, you will not receive any push notifications/alerts. If you accept, push
            notifications/alerts will be automatically sent to you.
        </div>

        <div className="common-page-paragraph">
            If you no longer wish to receive push notifications/alerts from the App, you may opt-out by changing
            your notification settings on your mobile device. With respect to other types of messaging or
            communications, such as emails, text messages, etc., you can unsubscribe or opt-out by either following
            the specific instructions included in such communications or by emailing us with your request at
            feedback@grazerapp.com.
        </div>

        <div className="common-page-paragraph">
            The App may allow access to or make available opportunities for you to view certain content and receive
            other products, services and/or other materials based on your location. To make these opportunities
            available to you, the App will determine your location using one or more reference points, such as GPS,
            Bluetooth and/or software within your mobile device. If you have set your mobile device to disable GPS,
            Bluetooth or other location determining software or do not authorize the App to access your location data,
            you will not be able to access such location-specific content, products, services and materials.
        </div>

        <div className="common-page-paragraph">
            For more about how the App uses and retains your information, please read the Privacy Policy.
        </div>

        <div className="common-page-subtitle">
            7. DISCLAIMER
        </div>

        <div className="common-page-paragraph">
            Brace yourselves, this may look daunting but it is very important!
        </div>

        <div className="common-page-paragraph">
            THE APP, SITE, OUR CONTENT, AND MEMBER CONTENT ARE ALL PROVIDED TO YOU "AS IS" AND "AS AVAILABLE"
            WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, FITNESS FOR A
            PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT.
        </div>

        <div className="common-page-paragraph">
            SHOULD APPLICABLE LAW NOT PERMIT THE FOREGOING EXCLUSION OF EXPRESS OR IMPLIED WARRANTIES, THEN WE
            GRANT THE MINIMUM EXPRESS OR IMPLIED WARRANTY REQUIRED BY APPLICABLE LAW. NO ADVICE OR INFORMATION,
            WHETHER ORAL OR WRITTEN, SHALL CREATE ANY WARRANTY, REPRESENTATION OR GUARANTEE NOT EXPRESSLY STATED
            IN THIS SECTION.
        </div>

        <div className="common-page-paragraph">
            ​ADDITIONALLY, WE DO NOT MAKE ANY WARRANTIES THAT THE APP OR SITE WILL BE UNINTERRUPTED, SECURE OR
            ERROR-FREE OR THAT YOUR USE OF THE APP OR SITE WILL MEET YOUR EXPECTATIONS, OR THAT THE APP, SITE, OUR
            CONTENT, ANY MEMBER CONTENT, OR ANY PORTION THEREOF, IS CORRECT, ACCURATE, OR RELIABLE. YOUR USE OF
            THE APP OR SITE IS AT YOUR OWN RISK. YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER MEMBERS.
            GRAZER HOLDINGS LIMITED IS NOT RESPONSIBLE FOR THE CONDUCT OF ANY USER. GRAZER DOES NOT CONDUCT
            CRIMINAL BACKGROUND CHECKS ON ITS MEMBERS.
        </div>

        <div className="common-page-paragraph">
            NEITHER US NOR ANY OWNER WILL BE LIABLE FOR ANY DAMAGES, DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL,
            SPECIAL, OR PUNITIVE, INCLUDING, WITHOUT LIMITATION, LOSS OF DATA, INCOME, PROFIT OR GOODWILL, LOSS OF
            OR DAMAGE TO PROPERTY AND CLAIMS OF THIRD PARTIES ARISING OUT OF YOUR ACCESS TO OR USE OF THE APP, SITE,
            OUR CONTENT, OR ANY MEMBER CONTENT, HOWEVER CAUSED, WHETHER BASED ON BREACH OF CONTRACT, TORT
            (INCLUDING NEGLIGENCE), PROPRIETARY RIGHTS INFRINGEMENT, PRODUCT LIABILITY OR OTHERWISE.
        </div>

        <div className="common-page-paragraph">
            THE FOREGOING SHALL APPLY EVEN IF WE WERE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IF YOU BECOME
            DISSATISFIED IN ANY WAY WITH THE APP OR SITE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO STOP YOUR
            USE OF THE APP AND SITE.
        </div>

        <div className="common-page-paragraph">
            YOU HEREBY WAIVE ANY AND ALL CLAIMS ARISING OUT OF YOUR USE OF THE APP OR SITE. BECAUSE SOME STATES
            DO NOT ALLOW THE DISCLAIMER OF IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN TYPES OF
            DAMAGES, THESE PROVISIONS MAY NOT APPLY TO YOU. IF ANY PORTION OF THIS LIMITATION ON LIABILITY IS FOUND
            TO BE INVALID OR UNENFORCEABLE FOR ANY REASON, THEN OUR AGGREGATE LIABILITY SHALL NOT EXCEED ONE
            HUNDRED DOLLARS ($100).
        </div>

        <div className="common-page-paragraph">
            THE LIMITATION OF LIABILITY HEREIN IS A FUNDAMENTAL ELEMENT OF THE BASIS OF THE BARGAIN AND REFLECTS
            A FAIR ALLOCATION OF RISK. THE APP AND SITE WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS AND YOU
            AGREE THAT THE LIMITATIONS AND EXCLUSIONS OF LIABILITY, DISCLAIMERS AND EXCLUSIVE REMEDIES SPECIFIED
            HEREIN WILL SURVIVE EVEN IF FOUND TO HAVE FAILED IN THEIR ESSENTIAL PURPOSE.
        </div>

        <div className="common-page-subtitle">
            8. INDEMNITY
        </div>

        <div className="common-page-paragraph">
            All the actions you make and information you post on Grazer remain your responsibility.
            Therefore, you agree to indemnify, defend, release, and hold us, and our partners, licensors,
            affiliates, contractors, officers, directors, employees, representatives and agents, harmless,
            from and against any third party claims, damages (actual and/or consequential), actions, proceedings,
            demands, losses, liabilities, costs and expenses (including reasonable legal fees) suffered or
            reasonably incurred by us arising as a result of, or in connection with: {'\n'}
            1. any negligent acts, omissions or wilful misconduct by you; {'\n'}
            2. your access to and use of the App; {'\n'}
            3. the uploading or submission of Content to the App by you; {'\n'}
            4. any breach of these Terms by you; and/or {'\n'}
            5. your violation of any law or of any rights of any third party. {'\n'}
        </div>

        <div className="common-page-paragraph">
            We retain the exclusive right to settle, compromise and pay any and all claims or causes of action which
            are brought against us without your prior consent. If we ask, you will co-operate fully and reasonably
            as required by us in the defence of any relevant claim.
        </div>

        <div className="common-page-subtitle">
            9. DIGITAL MILLENNIUM COPYRIGHT ACT
        </div>

        <div className="common-page-paragraph">
            Grazer Holdings Limited has adopted the following policy towards copyright infringement in accordance
            with the Digital Millennium Copyright Act (the "DMCA"). If you believe any Member Content or Our Content
            infringes upon your intellectual property rights, please submit a notification alleging such
            infringement ("DMCA Takedown Notice") including the following:
        </div>

        <div className="common-page-paragraph">
            ​1. A physical or electronic signature of a person authorized to act on behalf of the owner of an
            exclusive right that is allegedly infringed; {'\n'}
            2. Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted
            works at a single online site are covered by a single notification, a representative list of such works; {'\n'}
            3. Identification of the material claimed to be infringing or to be the subject of infringing activity
            and that is to be removed or access disabled and information reasonably sufficient to permit the service
            provider to locate the material; {'\n'}
            4. Information reasonably sufficient to permit the service provider to contact you, such as an address,
            telephone number, and, if available, an electronic mail; {'\n'}
            5. A statement that you have a good faith belief that use of the material in the manner complained
            of is not authorized by the copyright owner, its agent, or the law; and {'\n'}
            6. A statement that, under penalty of perjury, the information in the notification is accurate and you
            are authorized to act on behalf of the owner of the exclusive right that is allegedly infringed. {'\n'}
        </div>

        <div className="common-page-paragraph">
            Any DMCA Takedown Notices should be sent to: feedback@grazerapp.com
        </div>

        <div className="common-page-subtitle">
            10. THIRD-PARTY APP STORE
        </div>

        <div className="common-page-paragraph">
            The following additional terms and conditions apply to you if you download the App from a Third Party
            Store. To the extent that the other terms and conditions of these Terms are less restrictive than, or
            otherwise conflict with, the terms and conditions of this Section, the more restrictive or conflicting
            terms and conditions in this Section will apply, but solely with respect to the App and the Third Party
            Store. You acknowledge and agree that:
        </div>

        <div className="common-page-paragraph">
            ​1. These Terms are concluded solely between you and Grazer Holdings Limited and not with the providers
            of the Third Party Store, and Grazer Holdings Limited (and not the Third Party Store providers) is
            solely responsible for the App and the content thereof. To the extent that these Terms provide for
            usage rules for the App which are less restrictive or in conflict with the applicable terms of service
            of the Third Party Store from which you obtain the App, the more restrictive or conflicting term of the
            Third Party Store will take precedence and will apply.
        </div>

        <div className="common-page-paragraph">
            2. The Third Party Store provider has no obligation whatsoever to provide any maintenance and support
            services with respect to the App. Grazer Holdings Limited is solely responsible for any product
            warranties, whether express or implied by law, to the extent not effectively disclaimed. The Third Party
            Store provider will have no warranty obligation whatsoever with respect to the App, and any other
            claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to
            any warranty will be the sole responsibility of Grazer Holdings Limited.
        </div>

        <div className="common-page-paragraph">
            3. Grazer Holdings Limited, not the Third Party Store provider, is responsible for addressing any
            claims you or any third party may have relating to the App or your possession and/or use of the App,
            including, but not limited to: (i) product liability claims; (ii) any claim that the App fails to
            conform to any applicable legal or regulatory requirement; (iii) claims arising under consumer
            protection or similar legislation; and/or (iv) intellectual property infringement claims.
        </div>

        <div className="common-page-paragraph">
            4. The Third Party Store provider and its subsidiaries are third party beneficiaries of this Agreement,
            and, upon your acceptance of these Terms, the Third Party Store provider from whom you obtained the App
            will have the right (and will be deemed to have accepted the right) to enforce these Terms against you
            as a third-party beneficiary thereof.
        </div>

        <div className="common-page-subtitle">
            11. MISCELLANEOUS
        </div>

        <div className="common-page-paragraph">
            There are a few more things we need to mention before you can use Grazer.
            Please bear with us, we're nearly done!
        </div>

        <div className="common-page-subsection-title">
            ​Firstly, those standard clauses at the end of most contracts (boring, we know)
        </div>

        <div className="common-page-paragraph">
            These Terms, which we may amend from time to time, constitute the entire agreement between you and the
            Grazer Holdings Limited. The Terms supersede all previous agreements, representations and arrangements
            between us (written or oral). Nothing in this clause shall limit or exclude any liability for
            fraudulent misrepresentation.
        </div>

        <div className="common-page-paragraph">
            ​Grazer Holdings Limited has taken reasonable steps to ensure the currency, availability, correctness
            and completeness of the information contained on Grazer and provides that information on an "as is",
            "as available" basis. Grazer Holdings Limited does not give or make any warranty or representation of
            any kind about the information contained on Grazer, whether express or implied. Use of Grazer and the
            materials available on it is at your sole risk. Grazer Holdings Limited cannot be held responsible for
            any loss arising from the transmission, use of data, or inaccurate User Content.
        </div>

        <div className="common-page-paragraph">
            ​You are responsible for taking all necessary precautions to ensure that any material you may obtain
            from Grazer is free of viruses or other harmful components. You accept that Grazer will not be provided
            uninterrupted or error-free, that defects may not be corrected or that Grazer Holdings Limited, or the
            server that makes it available, are free of viruses or bugs, spyware, Trojan horse or any similar
            malicious software. Grazer Holdings Limited is not responsible for any damage to your computer hardware,
            computer software, or other equipment or technology including, but without limitation damage from any
            security breach or from any virus, bugs, tampering, fraud, error, omission, interruption, defect, delay
            in operation or transmission, computer line or network failure or any other technical or other malfunction.
        </div>

        <div className="common-page-paragraph">
            We know our Terms are awesome, but we may have to change them now and again
        </div>

        <div className="common-page-paragraph">
            As Grazer grows, we might have to make changes to these Terms so we reserve the right to modify,
            amend or change the Terms at any time (a "Change"). If we do this then the Changes will be posted on
            this page and we will indicate the Effective Date of the updates at the bottom of the Terms.
        </div>

        <div className="common-page-paragraph">
            In certain circumstances, we may send an email to you notifying you of a Change. You should regularly
            check this page for notice of any Changes – we want our users to be as informed as possible.
        </div>

        <div className="common-page-paragraph">
            Your continued use of Grazer following any Change constitutes your acceptance of the Change and you
            will be legally bound by the new updated Terms. If you do not accept any Changes to the Terms, you
            should stop using Grazer immediately.
        </div>

        <div className="common-page-subsection-title">
            ​Some more legal mumbo jumbo
        </div>

        <div className="common-page-paragraph">
            If, for any reason, any of the Terms are declared illegal, invalid or otherwise unenforceable by a
            court of a competent jurisdiction, then to the extent that term is illegal, invalid or unenforceable,
            it shall be severed and deleted from the Terms and the remainder of the Terms shall survive, remain in
            full force and effect and continue to be binding and enforceable.
        </div>

        <div className="common-page-paragraph">
            ​No failure or delay in exercising any right, power or privilege under the Terms shall operate as a
            waiver of such right or acceptance of any variation of the Terms and nor shall any single or partial
            exercise by either party of any right, power or privilege preclude any further exercise of the right or
            the exercise of any other right, power or privilege.
        </div>

        <div className="common-page-paragraph">
            ​You represent and warrant that: {'\n'}
            1. you are not located in a country that is subject to a U.S. Government embargo, or that has been
            designated by the U.S. Government as a "terrorist supporting" country; and {'\n'}
            2. you are not listed on any U.S. Government list of prohibited or restricted parties
        </div>

        <div className="common-page-paragraph">
            By using the App, you agree and acknowledge that Grazer is a global app operating through servers
            located in a number of countries around the world, including the United States. If you live in a
            country with data protection laws, the storage of your personal data may not provide you with the
            same protections as you enjoy in your country of residence. By submitting your personal information,
            or by choosing to upgrade the services you use, or by making use of the applications available on
            Grazer, you agree to the transfer of your personal information to, and storage and processing of your
            personal information in, any such countries and destinations.
        </div>

        <div className="common-page-paragraph">
            ​The App may contain links to third-party websites or resources. In such cases, you acknowledge
            and agree that we are not responsible or liable for: {'\n'}
            1. the availability or accuracy of such websites or resources; or {'\n'}
            2. the content, products, or services on or available from such websites or resources.
        </div>

        <div className="common-page-paragraph">
            Links to such websites or resources do not imply any endorsement. You acknowledge sole responsibility
            for and assume all risk arising from your use of any such websites or resources. Framing, in-line
            linking or other methods of association with the App are expressly prohibited without first
            obtaining our prior written approval.
        </div>

        <div className="common-page-paragraph">
            ​These Terms, and any rights and licenses granted hereunder, may not be transferred or assigned by you,
            but may be assigned by us without restriction.
        </div>

        <div className="common-page-subtitle">
            12. ABOUT US
        </div>

        <div className="common-page-paragraph">
            The Terms constitute a binding legal agreement between you as user (“you”) and Grazer Holdings Limited
            (“we” or “us”). Grazer Holdings Limited, registered office is at 44 Furze Lane, Purley, Surrey, England, CR83EG.
        </div>

        <div className="common-page-subsection-title">
            ​Effective date
        </div>

        <div className="common-page-paragraph">
            The Terms were last updated on: 28 December 2019.
        </div>

        <img
            src={grazerLogoDark}
            alt="grazer-logo"
            className="common-page__grazer-logo"
        />
    </div>
)
