import React from 'react'
import '@dotlottie/player-component'
import teeth from '../../resources/loading.lottie'
import { RotatingBanner } from '../../components/RotatingBanner'
import plantBasedConnectionBadge from '../../resources/plantBasedConnectionBadge.lottie'
import './index.scss'

export const Download = () => {
    return (
        <div className="download-page">
            <div className="download-page__inner-content">
                <div className="download-page-left">
                    <div className="download-page__title-container">
                        <span className="common-main-page-title">
                            The meatless <br /> matchmaker
                            <dotlottie-player
                                class="download-page__plant-badge"
                                src={plantBasedConnectionBadge}
                                autoplay
                                loop
                            />
                        </span>

                        <p className="download-page__subtitle">
                            FIND MEAT-FREE <br />
                            DATES AND FRIENDS
                        </p>

                        <div className="download-page__button-container">
                            <a
                                className="common-button download-page__button"
                                href="https://apps.apple.com/gb/app/grazer-dating-friends/id1194610571"
                                target="_blank"
                            >
                                iOS
                            </a>
                            <a
                                className="common-button download-page__button"
                                href="https://play.google.com/store/apps/details?id=com.grazerapp.grazer&hl=en_GB"
                                target="_blank"
                            >
                                ANDROID
                            </a>
                        </div>
                    </div>
                </div>
                <div className="download-page-right">
                    <dotlottie-player class="download-page__teeth" src={teeth} autoplay loop />
                </div>
            </div>

            <RotatingBanner light={false} />
        </div>
    )
}
