import React from 'react'
import mouth from '../../resources/mouth.gif'
import './index.css'

export const Careers = () => {
    return (
        <div className="careers-page">
            <div className="careers-page-left">
                <span className="common-main-page-title">
                    Think <br /> we're tasty?
                </span>

                <p className="careers-page__subtitle">
                    Join our mission lead team. Drop us a line <br />
                    if you think we’re a good match.
                </p>

                <div className="careers-page__button-container">
                    <a
                        className="common-button careers-page__button"
                        href="mailto:info@grazerapp.com"
                    >
                        SAY HI
                    </a>

                    <a
                        className="common-button careers-page__button"
                        href="https://www.linkedin.com/company/grazerapp"
                        target="_blank"
                        rel="noopener"
                    >
                        VACANCIES
                    </a>
                </div>
            </div>
            <div className="careers-page-right">
                <img alt="mouth" src={mouth} />
            </div>
        </div>
    )
}
