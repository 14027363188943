import React from 'react'
import '../common.scss'
import './index.scss'

export const Guidelines = () => (
    <div className="common-page-inner-content">
        <div className="common-page-title">Guidelines</div>

        <div className="common-page-subtitle">Welcome to Grazer. The meatless matchmaker.</div>

        <div className="common-page-paragraph">
            We don’t do beef around here. Grazer is a safe space for people reducing and eliminating
            animal products from their lifestyle. Please follow our guidelines to have a safe and
            enjoyable experience both online and offline. Failure to adhere to our guidelines may
            result in losing access to Grazer.
        </div>

        <div className="common-page-subtitle">Be You - Be truthful and use images of yourself.</div>

        <div className="common-page-paragraph">
            Don’t pretend to be someone else or use Grazer as a marketplace.
        </div>

        <div className="common-page-subtitle">
            Be Nice - Treat others with respect and kindness.
        </div>

        <div className="common-page-paragraph">
            We will not tolerate abuse against veganism or vegetarianism. Threats, bullying,
            intimidation or stalking will be taken seriously. Any content promoting racism, sexism,
            misogyny, targeting individuals or groups based on their race, ethnicity, religion,
            disability, gender, age, national origin, sexual orientation, or gender identity is
            strictly prohibited and will result in your account being permanatly banned.
        </div>

        <div className="common-page-subtitle">
            Be Safe - Take care when sharing personal information.
        </div>

        <div className="common-page-paragraph">
            Don’t publicly broadcast your or someone else’s personal information.
        </div>

        <div className="common-page-subtitle">Help Us - Always report bad behaviour</div>

        <div className="common-page-paragraph">Help us keep Grazer a safe and positive space.</div>

        <div className="common-page-subtitle">Photo Guidelines:</div>

        <div className="common-page-paragraph">
            Photos containing the below are not allowed on Grazer. If you upload any of the below
            you might be banned from the platform.{'\n'}
            • Dead animals, meat, abuse against animals, hunting{'\n'}
            • Hate towards vegans or vegetarians{'\n'}
            • Hate targeted towards individuals or groups of people{'\n'}
            • Children on their own, they must be with an adult and fully clothed in the photo{'\n'}
            • Violence, gore{'\n'}
            • Guns{'\n'}
            • Pornography{'\n'}
            • Nudity and genitals{'\n'}
            • Soliciting{'\n'}
            • Promotion{'\n'}

        </div>

        <div className="common-page-paragraph">
            Breaking our guidelines will result in a warning. Ignore this warning and you’ll lose
            your account. We can decide to block you without warning at our discretion.
        </div>
    </div>
)
