import React from 'react'
import aboutUsDesktop from '../../resources/aboutUsDesktop.png'
import aboutUsMobile1 from '../../resources/aboutUsMobile1.png'
import aboutUsMobile2 from '../../resources/aboutUsMobile2.png'
import './index.css'

export const AboutUs = () => (
    <div className="us-page__content">
        <img src={aboutUsDesktop} alt="" className="us-page__background" />
        <img src={aboutUsMobile2} alt="" className="us-page__mobile-background-2" />

        <div className="us-page__title-container">
            <div className="us-page__title">
                For plant people <br />
                by plant people
            </div>

            <p className="us-page__subtitle">
                We’re a team of passionate vegans who felt it was about time <br className="us-page__break" />
                to have an app dedicated to our growing community. <br className="us-page__break" />
                We wanted to create a space for plant people to thrive, not hide.
            </p>
        </div>

        <img src={aboutUsMobile1} className="us-page__mobile-background-1" />
    </div>
)
