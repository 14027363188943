import React from 'react'
import { RotatingBanner } from '../../components/RotatingBanner'
import plantPeopleFray from '../../resources/plantPeopleFray.svg'
import plantPeoplePhone from '../../resources/plantPeoplePhone.png'
import slideshow1 from '../../resources/slideshow1.jpg'
import slideshow2 from '../../resources/slideshow2.jpg'
import slideshow3 from '../../resources/slideshow3.jpg'
import slideshow4 from '../../resources/slideshow4.jpg'
import slideshow5 from '../../resources/slideshow5.jpg'
import slideshow6 from '../../resources/slideshow6.jpg'

import '@dotlottie/player-component'
import personalityPlateAnimation from '../../resources/personalityPlate.lottie'
import personalityPlateVerticalAnimation from '../../resources/personalityPlateVertical.lottie'
import stars1 from '../../resources/stars1.lottie'
import stars2 from '../../resources/stars2.lottie'
import stars3 from '../../resources/stars3.lottie'
import datesBadge from '../../resources/datesBadge.lottie'
import friendsBadge from '../../resources/friendsBadge.lottie'
import plantBasedConnectionBadge from '../../resources/plantBasedConnectionBadge.lottie'

import './index.scss'

const slideshowImages = [slideshow1, slideshow2, slideshow3, slideshow4, slideshow5, slideshow6]

export const Home = () => {
    const [loaded, setLoaded] = React.useState(false)
    const [slideshowIndex, setSlideshowIndex] = React.useState(0)

    React.useEffect(() => {
        let idx = 0

        if (loaded) {
            setInterval(() => {
                idx === slideshowImages.length - 1 ? (idx = 0) : (idx += 1)
                setSlideshowIndex(idx)
            }, [1200])
        }
    }, [loaded])

    return (
        <div className="home-page__container">
            <div className="home-page__headline-section">
                <div className="home-page__headline-section-left">
                    <div className="home-page__title-container">
                        <span className="common-main-page-title home-page__title">
                            The meatless <br /> matchmaker
                            <dotlottie-player
                                class="home-page__plant-badge"
                                src={plantBasedConnectionBadge}
                                loop
                                autoplay
                            />
                        </span>

                        <span className="home-page__subtitle">
                            FIND MEAT-FREE <br /> DATES AND FRIENDS
                        </span>

                        <div className="home-page__title-actions-container">
                            <a
                                className="common-button"
                                href="https://apps.apple.com/gb/app/grazer-dating-friends/id1194610571"
                                target="_blank"
                                rel="noopener"
                            >
                                iOS
                            </a>

                            <a
                                className="common-button"
                                href="https://play.google.com/store/apps/details?id=com.grazerapp.grazer&hl=en_GB"
                                target="_blank"
                                rel="noopener"
                            >
                                ANDROID
                            </a>
                        </div>

                        <dotlottie-player
                            class="home-page__friends-badge"
                            src={friendsBadge}
                            loop
                            autoplay
                        />
                        <dotlottie-player
                            class="home-page__dates-badge"
                            src={datesBadge}
                            loop
                            autoplay
                        />
                    </div>
                </div>

                <div className="home-page__headline-section-right">
                    <img
                        className="home-page__headline-section__image"
                        src={slideshowImages[slideshowIndex]}
                        onLoad={() => setLoaded(true)}
                    />
                </div>
            </div>

            <RotatingBanner />

            <div className="home-page__movement-section">
                <div className="home-page__movement-section__title-container">
                    <div className="home-page__movement-section__title">
                        More leaf, <br />
                        no beef
                    </div>

                    <div className="home-page__movement-section__subtitle-container">
                        <div className="home-page__movement-section__subtitle">
                            THE MEAT-FREE <br />
                            MOVEMENT IS HERE
                        </div>

                        <p className="home-page__movement-section__paragraph">
                            And so is Grazer, the dating and friend-finding app dedicated solely for
                            vegans and vegetarians.
                        </p>
                    </div>
                </div>
            </div>

            <div className="home-page__plant-people-section">
                <div className="home-page__plant-people-section__content">
                    <div className="home-page__plant-people-section__phone-container">
                        <dotlottie-player
                            class="home-page__plant-people-section__star1"
                            src={stars1}
                            loop={true}
                            autoplay
                        />
                        <img
                            className="home-page__plant-people-section__phone"
                            src={plantPeoplePhone}
                        />
                        <dotlottie-player
                            class="home-page__plant-people-section__star2"
                            src={stars2}
                            loop={true}
                            autoplay
                        />
                    </div>
                    <div className="home-page__plant-people__title-container">
                        <div className="common-main-page-title home-page__plant-people__title">
                            Find your <br />
                            plant people
                        </div>

                        <p className="home-page__plant-people__subtitle">
                            Connect with the consciously <br class="mobile-break" />like-minded. <br />
                            Get a slice of the action on Grazer.
                        </p>

                        <dotlottie-player
                            class="home-page__plant-people-section__star3"
                            src={stars3}
                            loop={true}
                            autoplay
                        />
                    </div>
                </div>
                <div className="home-page__plant-people-section__fray"></div>
            </div>

            <div className="home-page__personality-section">
                <div className="home-page__personality-section--left">
                    <div className="common-main-page-title home-page__personality-section__title">
                        Put your <br />
                        personality <br />
                        on a plate
                    </div>

                    <p className="home-page__personality-section__subtitle">
                        Add plant-based flavour to your profile and <br />
                        express yourself in a space built for you
                    </p>
                </div>

                <div className="home-page__personality-section--right">
                    <dotlottie-player
                        class="home-page__personality-section__plate-vertical"
                        src={personalityPlateVerticalAnimation}
                        loop={true}
                        autoplay
                    />
                </div>
            </div>
        </div>
    )
}
