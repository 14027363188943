import React from 'react'
import '../common.scss'

export const Privacy = () => (
    <div className="common-page-inner-content">
        <div className="common-page-title">Privacy</div>

        <div className="common-page-subtitle">
            GRAZER PRIVACY POLICY
        </div>

        <div className="common-page-paragraph">
            Welcome to the Grazer Privacy Policy (“Policy”). This explains how we collect, store, protect and
            share your information, and with whom we share it. We suggest you read this in conjunction with our
            Terms and Conditions of Use. {'\n'}
            By downloading and installing our App (“App”), you agree that we
            can collect, transfer, store and use your information as described in this Policy. So please read
            carefully.
        </div>

        <div className="common-page-subtitle">
            COLLECTION OF INFORMATION
        </div>

        <div className="common-page-paragraph">
            When you download the App and create an account ("Account"), we may collect certain information
            ("Registration Information") about you, such as: Name; Age; Gender; Photographs; Email address; Date
            of Birth; Sexual preference; Nationality; Photographs; Location; Device Information and Login
            Information and friend lists for social media accounts that you connect your Grazer Account (this
            could include, for example, your Google, YouTube, Facebook, Twitter, and Instagram accounts ("Social
            Media Accounts"). {'\n'}
            Through your use of the App, we may automatically collect technical information about your device,
            such as its IP address or other unique device identifier. {'\n'}
            You can request to delete your data by emailing us at <a href="mailto:info@grazerapp.com">info@grazerapp.com</a>
        </div>

        <div className="common-page-subtitle">
            ADDITIONAL INFORMATION
        </div>

        <div className="common-page-paragraph">
            We may also collect additional information in connection with your use of Grazer, such as comments
            and posts, or if you use our electronic messaging function. {'\n'}
            If you contact our Customer Support team, we will receive your email address and may track your IP
            address as well as the information you send to us to help resolve your query. We will keep records of
            our communications with you, including any complaints that we receive from you about other Users (and
            from other Users about you). {'\n'}
            We recommend and encourage you (and all our members) to think carefully about the information you
            disclose about yourself. We also do not recommend that you put email addresses, URLs, instant messaging
            details, phone numbers, full names or addresses, credit card details, national identity numbers,
            drivers' licence details and other sensitive information on your profile which is open to
            abuse and misuse. {'\n'}
            Please be careful about posting sensitive details about yourself on your profile such as your religious
            denomination and health details. While you may voluntarily provide this information to us when you
            create your profile, including your sexual preferences and ethnic background, there is no requirement
            to do so. Please remember that photographs that you post on Grazer may reveal information about
            yourself as well. Where you do upload and choose to tell us sensitive information about yourself,
            you are explicitly consenting to our processing of your information and making this
            public to other users. {'\n'}
            When you post information about yourself or use the messaging function, the amount of personal
            information you share is at your own risk. If you post anything that is inconsistent with our
            Terms and Conditions of Use, we reserve the right to terminate your Account.
        </div>

        <div className="common-page-subtitle">
            USES MADE OF THE INFORMATION
        </div>

        <div className="common-page-paragraph">
            We may use your Registration Information to: offer you our services and features; contact you with
            information about the App subject to the “Marketing” section below; respond to any queries you raise
            when you contact us directly; notify you about changes to our service; carry out internal operations
            such as troubleshooting, data analysis, testing, research or for statistical purposes; investigate
            fraud, protect our legal rights, and to enforce our Terms of Use. {'\n'}
            We use technical information relating to your computer or mobile device: to administer our site and for
            internal operations, including troubleshooting, data analysis, testing, research, statistical and
            survey purposes; to improve our site to ensure that content is presented in the most effective manner
            for you and for your computer; to allow you to participate in interactive features of our service when
            you choose to do so; as part of our efforts to keep our site safe and secure.
        </div>

        <div className="common-page-subtitle">
            DISCLOSURE OF YOUR INFORMATION
        </div>

        <div className="common-page-paragraph">
            We will not disclose your Registration Information, except in the limited circumstances described here:
            Your Consent — If you consent, we may share or disclose your Registration Information. Service
            Providers — We engage certain trusted third parties to perform functions and provide services to us.
            We may share your Registration Information with these third parties, but only for the purposes of
            performing these functions and providing such services. Law and Harm — We will cooperate with law
            enforcement enquiries received from within or outside your country of residence. This may include
            preserving or disclosing any of your information, including your Registration Information, if we
            believe in good faith that it is necessary to comply with a law or regulation, or when we believe
            that disclosure is necessary to comply with a judicial proceeding, court order, or legal request, to
            protect the safety of any person, to address fraud, security or technical issues, or to protect our
            rights or property or those of third parties. In such cases, we may raise or waive any legal obligation
            or right available to us. {'\n'}
            Business Transfers — In the event that Grazer or any of our affiliates are involved in a bankruptcy,
            merger, acquisition, reorganisation or sale of assets, your information may be sold or transferred as
            part of that transaction. Other Information — We may share or disclose aggregated or otherwise
            anonymised information with third parties for industry analysis and demographic profiling, and to
            deliver targeted advertising.
        </div>

        <div className="common-page-subtitle">
            WHERE WE STORE YOUR PERSONAL DATA
        </div>

        <div className="common-page-paragraph">
            The data that we collect from you may be stored in the European Union as well as being transferred to,
            and stored at, a destination outside the European Economic Area ("EEA") such as the United States.
            It will also be processed by staff operating outside the EEA who work for us or for one of our
            suppliers. This includes staff engaged in, among other things, the provision of support services.
            By submitting your personal data, you agree to this transfer, storing or processing. Grazer will
            take all steps reasonably necessary to ensure that your data is treated securely and in accordance with
            this privacy policy. {'\n'}
            Unfortunately, the transmission of information via the internet is not completely secure.
            Although we will do our best to protect your personal data, we cannot guarantee the security of
            your data transmitted to our site; any transmission is at your own risk. Once we have received your
            Registration Information, we will use strict procedures and security features to try to prevent
            unauthorised access.
        </div>

        <div className="common-page-subtitle">
            LINKS
        </div>

        <div className="common-page-paragraph">
            The site may contain links to a number of sites such as Facebook (so that you can login via Facebook).
            If you follow a link to any of these sites, please note that they will have their own privacy policy
            and that Grazer does not accept any responsibility or liability for these policies. Please check these
            policies before you submit any personal data to these sites.
        </div>

        <div className="common-page-subtitle">
            MARKETING AND PUSH NOTIFICATIONS
        </div>

        <div className="common-page-paragraph">
            We may contact you using your email address to provide you with more information about the App, such as
            updates, when it will be available and where it can be downloaded from. {'\n'}
            You can opt-out of receiving our marketing communications at any time by using the unsubscribe link
            in any of our communications. {'\n'}
            If you download the App you will be asked to accept or deny push notifications/alerts. If you deny,
            you will not receive any push notifications/alerts. If you accept, push notifications/alerts will
            automatically be sent to you. If you no longer wish to receive push notifications/alerts from the App,
            you may opt-out by changing the notification settings on your mobile device.
        </div>

        <div className="common-page-subtitle">
            OUR POLICY TOWARDS AGE
        </div>

        <div className="common-page-paragraph">
            You have to be at least 18 years old to use the App. {'\n'}
            Our policy is to not market to children, minors or anyone under the age of 18. If you are under 18
            years of age, we request that you do not submit information to us. If we become aware that a child,
            minor or anyone under the age of 18 has registered with us, we will take steps to terminate that
            person’s registration and delete their Registration Information. If we do delete Registration
            Information because you violated our no children rules, we may retain your email and IP address to
            ensure that you do not try to get around our rules by creating a new profile.
        </div>

        <div className="common-page-subtitle">
            MODIFYING YOUR REGISTRATION INFORMATION
        </div>

        <div className="common-page-paragraph">
            You may access or modify the Registration Information you provided at any time.
            You can do this by visiting the application settings on your Facebook profile and following the
            instructions to remove the Grazer access permissions.
        </div>

        <div className="common-page-subtitle">
            ACCESS TO INFORMATION
        </div>

        <div className="common-page-paragraph">
            You may request, at any time, Grazer to provide you with information about the data it stores on you. {'\n'}
            In order to file a request, please contact info@grazerapp.com. We will process that request in
            line with any applicable local laws and our policies and procedures in place for dealing with such
            requests.
        </div>

        <div className="common-page-subtitle">
            CHANGES TO OUR PRIVACY POLICY
        </div>

        <div className="common-page-paragraph">
            We may revise this Policy from time to time. The most current version of the Policy will govern our
            use of your information and can be found at https://grazerapp.com/privacy. If we make a change to this
            Policy that, in our sole discretion, is material, we will notify you, for example, via an email. By
            continuing to access the site after those changes become effective, you agree to be bound by the
            revised Policy.
        </div>

        <div className="common-page-subtitle">
            COOKIES
        </div>

        <div className="common-page-paragraph">
            We collect information by placing cookies on your computer or mobile. A cookie is a piece of text
            stored on your computer or mobile by your web browser. They are basically a tool that stores
            information about website visits, recognises you and your preferences, ensures site functionality
            and enables us to provide the services you request with Grazer. {'\n'}
            Please visit this page regularly so that you are aware of any changes.
        </div>

        <div className="common-page-subtitle">
            CONTACT
        </div>

        <div className="common-page-paragraph">
            Questions, comments and requests regarding this privacy policy are welcomed and should be addressed
            to info@grazerapp.com.
        </div>

        <div className="common-page-subtitle">
            OUR DETAILS
        </div>

        <div className="common-page-paragraph">
            Grazer is owned and operated by Grazer Holdings Limited. Our registered office is at 44 Furze Lane,
            Purley, Surrey, England, CR83EG.
        </div>

        <div className="common-page-subsection-title">
            Effective Date:
        </div>

        <div className="common-page-paragraph">
            These Terms were last updated on 28 December 2019.
        </div>
    </div>
)
