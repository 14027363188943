import React from 'react'
import { BrowserRouter as Router, Route, Switch, withRouter, useLocation } from 'react-router-dom'
import { Home } from '../../pages/Home'
import { AboutUs } from '../../pages/AboutUs'
import { Terms } from '../../pages/Terms'
import { Careers } from '../../pages/Careers'
import { Privacy } from '../../pages/Privacy'
import { Guidelines } from '../../pages/Guidelines'
import { Press } from '../../pages/Press'
import { Mission } from '../../pages/Mission'
import { Download } from '../../pages/Download'
import { Contact } from '../../pages/Contact'
import { PageHeader } from '../PageHeader'
import { PageFooter } from '../PageFooter'
import {
    aboutUsUrl,
    careersUrl,
    contactUrl,
    downloadUrl,
    guidelinesUrl,
    missionUrl,
    pressUrl,
    privacyUrl,
    termsUrl,
} from '../../const'
import './index.css'

const HistoryListener = () => {
    window.scrollTo(0, 0)
    return null
}

const ScrollToTop = withRouter(HistoryListener)
const PageContainer = () => {
    const location = useLocation()
    return (
        <div className={'page-container page-container--' + location.pathname.substring(1)}>
            <PageHeader />

            <div className="page-content">
                <ScrollToTop />
                <Switch>
                    <Route path={aboutUsUrl}>
                        <AboutUs />
                    </Route>
                    <Route path={careersUrl}>
                        <Careers />
                    </Route>
                    <Route path={contactUrl}>
                        <Contact />
                    </Route>
                    <Route path={downloadUrl}>
                        <Download />
                    </Route>
                    <Route path={guidelinesUrl}>
                        <Guidelines />
                    </Route>
                    <Route path={missionUrl}>
                        <Mission />
                    </Route>
                    <Route path={pressUrl}>
                        <Press />
                    </Route>
                    <Route path={privacyUrl}>
                        <Privacy />
                    </Route>
                    <Route path={termsUrl}>
                        <Terms />
                    </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>
            </div>

            <PageFooter />
        </div>
    )
}

const App = () => {
    return (
        <Router onUpdate={() => window.scrollTo(0, 0)}>
            <PageContainer />
        </Router>
    )
}

export const Application = App
