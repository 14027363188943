import React from 'react'
import ReactDOM from 'react-dom'
import { Application } from './components/Application'
import * as serviceWorker from './serviceWorker'
import './resources/fonts/LinearSans.otf'
import './resources/fonts/Recoleta.otf'
import './index.css'

ReactDOM.render(
  <React.StrictMode>
    <Application />
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorker.unregister()
