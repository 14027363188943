import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
    aboutUsUrl,
    careersUrl,
    contactUrl,
    guidelinesUrl,
    missionUrl,
    pressUrl,
    privacyUrl,
    termsUrl
} from '../../const'
import grazerLogo from '../../resources/grazer-logo-footer.png'
import instaLogo from "../../resources/instaLogo.svg"
import twitterLogo from "../../resources/twitterLogo.svg"
import tiktokLogo from "../../resources/tiktokLogo.svg"
import './index.scss'

const MENU_LINKS = [
    { url: missionUrl, text: 'MISSION' },
    { url: careersUrl, text: 'CAREERS' },
    { url: privacyUrl, text: 'PRIVACY' },
    { url: guidelinesUrl, text: 'GUIDELINES' },
    { url: pressUrl, text: 'PRESS' },
    { url: contactUrl, text: 'CONTACT' },
    { url: termsUrl, text: 'T&Cs' },
    { url: aboutUsUrl, text: 'US' }
]

export const PageFooter = () => {
    const { pathname } = useLocation()

    return (
        <div className="page-footer__container">
            <div className="page-footer__logo-container">
                <img
                    src={grazerLogo}
                    alt="grazer-logo"
                    className="page-footer__grazer-logo"
                />
            </div>

            <div className="page-footer__links-container">
                <ul className="page-footer__links-list">
                    {MENU_LINKS.map(({ url, text }) => (
                        <li key={url} className={`page-footer__link ${pathname === url ? 'pressed' : ''}`}>
                            <Link to={url}>
                                {text}
                            </Link>
                        </li>
                    ))}
                </ul>

                <div className="page-footer__icons-container">
                    <a href="https://www.instagram.com/grazerapp" target="_blank" rel="noopener">
                        <img
                            alt="instagram-link"
                            src={instaLogo}
                        />
                    </a>

                    <a href="https://twitter.com/grazer_app" target="_blank" rel="noopener">
                        <img
                            alt="twitter-link"
                            src={twitterLogo}
                        />
                    </a>

                    <a href="https://www.tiktok.com/@grazerapp?lang=en" target="_blank" rel="noopener">
                        <img
                            alt="tiktok-link"
                            src={tiktokLogo}
                        />
                    </a>
                </div>
            </div>
        </div>
    )
}
