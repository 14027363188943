export const homeUrl = '/'
export const blogUrl = '/blog'
export const downloadUrl = '/download'
export const termsUrl = '/terms'
export const privacyUrl = '/privacy'
export const guidelinesUrl = '/guidelines'
export const pressUrl = '/press'
export const missionUrl = '/mission'
export const careersUrl = '/careers'
export const aboutUsUrl = '/about-us'
export const contactUrl = '/contact'
