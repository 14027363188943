import React from 'react'
import BBCLogo from '../../resources/bbc5.png'
import glamourLogo from '../../resources/glamourLogo.svg'
import metroLogo from '../../resources/metroLogo.svg'
import PBNLogo from '../../resources/PBNLogo.svg'
import sundayTimesLogo from '../../resources/sundayTimesLogo.svg'
import veganReviewLogo from '../../resources/veganReviewLogo.svg'
import vevolutionLogo from '../../resources/vevolutionLogo.svg'
import viceLogo from '../../resources/viceLogo.svg'
import bbcRadio from '../../resources/bbcradio.png'
import dailyMirror from '../../resources/daily-mirror.png'
import lbc from '../../resources/lbc.png'
import mashable from '../../resources/mashable.png'
import elPais from '../../resources/elpais.png'
import hypebae from '../../resources/hypebae.png'
import voxlogo from '../../resources/voxlogo.webp'
import './index.scss'

export const Press = () => {
    return (
        <div className="press-page">
            <div className="press-page__content">
                <div className="common-main-page-title">
                    Want to <br /> know more?
                </div>
                <div className="press-page__subtitle">
                    For all media enquiries
                </div>

                <a
                    className="common-button press-page-button"
                    href="mailto:info@grazerapp.com"
                >
                    GET IN TOUCH
                </a>

                <span className="press-page__mentioned">MENTIONED BY</span>

                <div className="press-page__logo-container">
                    <div>
                        <img alt="sundaytimes" src={sundayTimesLogo} className="sunday" />
                        <img alt="mashable" src={mashable} className="mashable" />
                        <img alt="bbc radio" src={bbcRadio} className="bbc" />
                        
                    </div>

                    <div>
                        <img alt="lbc" src={lbc} className="lbc" />
                        <img alt="vice" src={viceLogo} className="vice" />
                        <img alt="hypebae" src={hypebae} className="hypebae" />
                        
                    </div>

                    <div>
                        <img alt="metro" src={metroLogo} className="metro" />
                        <img alt="el pais" src={elPais} className="elpais" />
                        <img alt="vox" src={voxlogo} className="vox" />
                    </div>
                </div>
            </div>
        </div>
    )
}
