import React from 'react'
import '@dotlottie/player-component';
import cow from '../../resources/cow.png'
import moreLeaf from '../../resources/moreLeaf.png'
import placeholderImage from '../../resources/placeholderImage.jpg'
import missionFray from '../../resources/missionFray.svg'
import peoplePicture1 from '../../resources/peoplePicture1.jpg'
import plantBasedConnectionBadge from '../../resources/plantBasedConnectionBadge.lottie'
import './index.scss'

export const Mission = () => (
    <div className="mission-page">

        <div className="mission-page__section-1">
            <div className="common-page-title">
                Time to get <br /> serious.
            </div>

            <div className="mission-page__section-1__image-container">
                <div className="mission-page__section-1__image-wrapper">
                    <img src={cow} alt="cow" className="mission-page__cow" />
                </div>

                <div className="mission-page__section-1__title-wrapper">
                    <span className="mission-page__section-1__title">
                        72 BILLION <br /> LAND ANIMALS
                    </span>

                    <p className="mission-page__section-1__subtitle">
                        are killed every year to fill our plates. <br className="section-1-subtitle-break" />
                        Animals are suffering, and the planet is too.
                    </p>
                </div>
            </div>
        </div>

        <div className="mission-page__section-2">
            <img src={moreLeaf} alt="" loading="lazy" />

            <p>
                We believe the survival of the world and
                all its inhabitants depends on how fast plant-based living can be adopted into
                the mainstream
            </p>
        </div>

        <div className="mission-page__section-3">
            <div className="mission-page__section-3__title-container">
                <div className="mission-page__section-3__subtitle">
                    How can the plant-based movement be cataylsed?
                    We think the answer lies in the power of people.
                </div>

                <div className="mission-page__section-3__title">
                    PEOPLE COMING<br />
                    TOGETHER, UNITED, <br />
                    UNDER ONE <br />
                    COMMON CAUSE.
                </div>

                <div className="mission-page__section-3__fray" />
            </div>

            <div className="mission-page__section-3__image-container">
                <img className="mission-page__section-3__image" alt="" src={placeholderImage} loading="lazy" />
            </div>
        </div>

        <div className="mission-page__section-4">
            <div>
                <img src={peoplePicture1} alt="" className="mission-page__section-4-image" />
            </div>

            <div className="mission-page__section-4__right">
                <div className="mission-page__section-4__conversation">
                    <span className="mission-page__section-4__subtitle">
                        Every match on Grazer has the potential to spark
                    </span>

                    <span className="mission-page__section-4__title">CONVERSATION, IDEAS, LOVE & ACTIVISM.</span>

                    <span className="mission-page__section-4__subtitle">
                        Which is why we see Grazer as the connector to ignite and accelerate the vegan revolution.
                    </span>
                </div>

                <div className="mission-page__section-4__world">
                    <span className="mission-page__section-4__subtitle">
                        We’re not just a dating app.
                    </span>

                    <span className="mission-page__section-4__title">WE’RE HERE TO CHANGE THE WORLD,</span>

                    <span className="mission-page__section-4__subtitle">and have fun while doing it.</span>
                </div>

                <dotlottie-player
                    src={plantBasedConnectionBadge}
                    alt=""
                    class="mission-page__plant-based-badge"
                    loading="lazy"
                    autoplay
                    loop
                />
            </div>
        </div>

    </div>
)
