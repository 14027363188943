import React from 'react'
import hand from '../../resources/hand.svg'
import spikyCircle from '../../resources/spikyCircle.svg'
import curvedText from '../../resources/curvedText.svg'
import '../common.scss'
import './index.css'

export const Contact = () => (
    <div className="common-page-inner-content contact-page-inner-content">
        <div className="common-page-title contact-page__title">Contact</div>

        <div className="contact-page__content">
            <a href="mailto:info@grazerapp.com" className="contact-page__say-hi-container">
                <span className="contact-page__label contact-page__say-hi-label">For collaboration</span>
                <img className="contact-page__say-hi-icon" src={hand} />
            </a>

            <a href="mailto:info@grazerapp.com" className="contact-page__tell-us-container">
                <span className="contact-page__label contact-page__tell-us-label--span">Have a <br />success story?</span>
                <img className="contact-page__tell-us-label" src={curvedText} />
                <img className="contact-page__tell-us-icon" src={spikyCircle} />
            </a>

            <a href="mailto:info@grazerapp.com" className="contact-page__contact-us-container">
                CONTACT US
                <span className="contact-page__label contact-page__contact-us-label">All other enquiries</span>
            </a>
        </div>
    </div>
)
