import React from 'react'
import apple from '../../resources/apple.svg'
import leaf from '../../resources/leaf.svg'
import mushroom from '../../resources/mushroom.svg'
import './index.css'

export const RotatingBanner = ({ light = true }) => (
    <div className="home-page__rotating-banner">
        <div className={`home-page__rotating-banner__inner ${light ? 'home-page__rotating-banner__inner--light' : '' }`}>
            <img alt="apple" src={apple} /> FOR PLANT PEOPLE BY PLANT PEOPLE
            <img alt="leaf" src={leaf} /> FOR PLANT PEOPLE BY PLANT PEOPLE
            <img alt="mushroom" src={mushroom} /> FOR PLANT PEOPLE BY PLANT PEOPLE

            <img alt="apple" src={apple} /> FOR PLANT PEOPLE BY PLANT PEOPLE
            <img alt="leaf" src={leaf} /> FOR PLANT PEOPLE BY PLANT PEOPLE
            <img alt="mushroom" src={mushroom} /> FOR PLANT PEOPLE BY PLANT PEOPLE
        </div>
    </div>
)
